import React, { cloneElement } from "react";
import { tabs } from "./constants";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "gatsby";

export default (props: typeof tabs[number]) => {

   const { disableAction = true, imageUrl, details } = props;
   return (
      <div
         className="flex flex-row gap-4 md:gap-0 items-center p-8 rounded-lg min-h-[24rem] xl:min-h-80"
         style={{
            background: `url(${imageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
         }}
      >
         {/* Left Content */}
         <div className="w-full lg:w-2/3 text-left">

            <h2 className="text-xl md:text-3xl text-white font-bold mb-4">
               {details?.title}
            </h2>
            <p className=" text-white  mb-6 leading-7">
               {details?.description}
            </p>
            {!disableAction && (
               <Link to="/integrations">
                  <button
                     className="bg-transparent border-2 border-solid bg-primary-200 hover:border-primary-200 text-white hover:text-white py-2 px-4 rounded-lg shadow hover:bg-primary-200">
                     Browse All Connectors →
                  </button>
               </Link>
            )}

         </div>
      </div>
   );
};
