import React from "react";
import DetailedPanel from "./detailed-panel";
import { Lightning, ListNested } from "@styled-icons/bootstrap";
import { Dollar, TrendingUp,  } from "@styled-icons/boxicons-regular";


const tabs = [
   {
      title: "Launch integrations 10-100x faster",
      value: "scm",
      image: '/images/homepage/launch-integrationandspead.svg',
      description: (
         'Build once for an entire category of providers and see speed grow exponentially as you onboard more integrations, turning integrations into a scalable advantage over your competition'
      ),
      details: {
         title: 'Develop once for entire category of providers and launch integrations 10 to 100 times faster.',
         description: (
            `Build once for an entire provider category and scale integrations exponentially, turning them into a competitive advantage.`
         )
      },
      icon: <Lightning />,
      imageUrl: '/images/homepage/testimonial-1/launch-integration.jpg',
      content() {
         return (
            <DetailedPanel {...this} />
         )
      },
      disableAction: false
   },
   {
      title: "Fuel revenue growth",
      value: "pcr",
      image: '/images/homepage/growth.svg',
      description: (
         'Eliminate integration bottlenecks and drive revenue growth by swiftly delivering the integrations your prospects require.'
      ),
      details: {
         title: `Unlock revenue faster by removing integration bottlenecks and delivering
         the integrations customers need without delays.`,
         description: `Accelerate deal closures, onboard clients quickly, and reduce customer churn
         by driving increased product usage through seamless integrations. By
         unblocking stalled revenue streams, integrations become a powerful lever for
         growth and retention.`
      },
      icon: <TrendingUp />,
      imageUrl: '/images/homepage/testimonial-1/revenue-growth.jpg',
      content() {
         return (
            <DetailedPanel {...this} />
         )
      },
      disableAction: true
   },
   {
      title: "Lower cost of integrations",
      value: "ticketing",
      image: '/images/homepage/integrations.svg',
      description: (
         'Dramatically Cut Costs on integration delivery while freeing your development team to focus on core product innovation.'
      ),
      details: {
         title: `Eliminate repetitive work and lower integration costs by drastically reducing
         the engineering resources for development.`,
         description: (
            `Unizo helps you free engineering staff from Integrations to focus on core
            product innovation and strategic growth initiatives. By simplifying workflows
            and accelerating delivery, we not only saves time but also enables companies to
            allocate their resources where they matter most.`
         )
      },
      icon: <Dollar />,
      content() {
         return (
            <DetailedPanel {...this} />
         )
      },
      imageUrl: '/images/homepage/testimonial-1/lower-cost.jpg',
      disableAction: true
   },
   {
      title: "Boost customer reliability",
      value: "comms",
      image: '/images/homepage/reliability.svg',
      description: (
         'Ensure seamless operations with continuous integration monitoring and proactive diagnostics. Get actionable insights to keep your customer’s connections stable and experience uninterrupted.'
      ),
      details: {
         title: `Ensure system reliability with continuous integration health monitoring and
         proactive issue detection.`,
         description: (
            `Ensure smooth operations with continuous monitoring and proactive diagnostics. Get actionable insights to keep your customer’s connections stable and experience uninterrupted.`
         )
      },
      icon: <ListNested />,
      content() {
         return (
            <DetailedPanel {...this} />
         )
      },
      imageUrl: '/images/homepage/testimonial-1/boost-customer.jpg',
      disableAction: true
   },
];

const AUTO_SLIDE_INTERVAL = 3000

export { tabs, AUTO_SLIDE_INTERVAL }
