import React from 'react';
import Carousel from './carousel';
import { tabs } from './constants';


export default () => {
   return (
      <div className="mx-auto  text-center  pt-20 lg:pb-20 pb-10  ">
         <h2 className="h2 max-w-6xl m-auto">
            Supercharge your SaaS, scale your integrations with Unizo
         </h2>
         <Carousel className='hidden lg:grid mt-12' />
         <div className="relative layout grid grid-cols-1  my-10 lg:hidden gap-5">
            {tabs.map((tab: any, index) => {
               return (
                  <div className='h-full w-full' key={index}>{tab.content()}</div>
               )
            })}
         </div>
      </div>

   )
}