import React from 'react';
import '../../styles/pricing.css';

import NeonGradientCard from '../../components/common/UI/neon-border'
import { HeroBanner } from '../../components/homepage/herobanner';
import HelpSection from '../../components/homepage/HelpSection';
import CommunitySection from '../../components/homepage/CommunitySection';
import Layout from '../../components/common/Layout';
import { IntegrationPoints } from '../../components/homepage/IntegrationPoints';

// why choose product
import WhyChoose from '../../components/homepage/why-choose-unz'
import { cva } from 'class-variance-authority';
import { cn } from '../../utils/common';

const nodes = [{
   title: 'SaaS ',
   description: 'Streamlined integration with Unizo cloud-based service, ideal for most customers seeking simplicity and scalability.',
   extra: [
      { name: 'Learn More →', to: 'https://unizo.io/platform/security-and-reliability' }
   ],
   image: {
      src: '/icon-set/cloud.svg',
      alt: 'SaaS'
   }
},
{
   title: 'Self-Managed ',
   description: 'Control and compliance in your hands. Deploy and manage integrations on your own infrastructure for enhanced security.',
   extra: [
      { name: 'Learn More →', to: 'https://unizo.io/platform/security-and-reliability' }
   ],
   image: {
      src: '/icon-set/deployment-model.svg'
   }
},
{
   title: 'Integration Expertise',
   description: 'Unizo simplifies the integration process, freeing you up to focus on core product development and innovation.',
   extra: [
      { name: 'Learn More →', to: 'https://unizo.io/platform/security-and-reliability' }
   ],
   image: {
      src: '/icon-set/integrations.svg'
   }
}
]

const Home = () => {
   return (
      <Layout disablePageTitleSuffix title='Unizo - Integrations for Cybersecurity, Devops'>
         <HeroBanner />

         <WhyChoose />

         <ImagePanel />
         <IntegrationPoints
            nodes={nodes}
            rootClassName='!my-0'
            title='Enterprise-Ready Platform'
         />
         <div className="z-0 sticky">
            <HelpSection />
         </div>
         <CommunitySection className='pt-64' />
      </Layout>
   )
};

const imageVariants = cva(
   cn("portal_image ml-auto w-full p-2 m-auto ")
)

const ImagePanel = () => {
   return (
      <div className='layout 2xl:w-[1300px] m-auto mb-16 lg:mb-32' >
            <img alt="portal_main_img" className={imageVariants()} src="/images/homepage/hero/home-hero-image-3.svg" />
      </div >
   )
}

export default Home;
