import React, { HTMLProps } from 'react';
import { cn } from '../../../../utils/common';
import { cva } from 'class-variance-authority';

type Props = {
   length: number
   activeIndex: number
   onSelect?: (selectedIndex: number) => void
} & HTMLProps<HTMLDivElement>

const indicatorVariant = cva(
   cn(
      'h-3 w-3 border-2 border-solid border-primary-200  rounded-full cursor-pointer hover:scale-125'
   ),
   {
      variants: {
         isSelected: {
            true: 'bg-primary-200 scale-125',
            false: 'bg-white'
         }
      }
   }
)

export default ({ className, length, activeIndex, onSelect: onSelectProp }: Props) => {
   return (
      <div className={cn('w-full flex justify-center', className)}>
         <div className="flex flex-row gap-5">
            {new Array(length).fill({}).map((_skip, index) => {
               const isSelected = activeIndex === index
               return (
                  <span
                     key={index}
                     className={indicatorVariant({ isSelected })}
                     onClick={(e) => onSelectProp && onSelectProp(index)}
                  ></span>
               )
            })}
         </div>
      </div>
   )
}