import React, { HTMLProps, useEffect, useMemo, useState } from 'react';
import { cva } from 'class-variance-authority';

// tabs
import { AUTO_SLIDE_INTERVAL, tabs } from './constants';

import { cn } from '../../../utils/common';
import Card from './card';
import Slider from './slider';
import Indicator from './slider/indicator';

// root-variants
const gridRootVariants = cva(
   cn(
      'grid grid-cols-4 gap-5 mt-4'
   ),
   {
      variants: {

      }
   }
);

interface Props extends HTMLProps<HTMLDivElement> { };

export default (props: Props) => {

   const { className, ...rest } = props;

   // states
   const [activeIndex, setActiveIndex] = useState<number>(0);
   const [resetKey, setResetKey] = useState(0);

   const onSelectCard = (selectedTag: typeof tabs[number]) => {
      setResetKey(prev => prev + 1);
      setActiveIndex(tabs?.findIndex((i) => i?.value === selectedTag?.value))
   };

   const slides = useMemo(() => {

      return (
         tabs.map((tab, index) => {
            return {
               id: index, content: tab.content()
            }
         })
      )
   }, [tabs]);

   useEffect(() => {
      const intervalId = setInterval(() => {
         setActiveIndex((prev) => {
            return prev !== tabs?.length - 1 ? prev + 1 : 0;
         });
      }, AUTO_SLIDE_INTERVAL);;

      return () => {
         clearInterval(intervalId);
      }
   }, [resetKey])


   return (
      <div
         {...rest}
         className={cn('layout', className)}
      >
         <div
            className={gridRootVariants()}
         >
            {tabs.map((tab, index) => {

               const isActive = index === activeIndex;
               return (
                  <Card
                     key={index}
                     tab={tab}
                     onClick={() => void onSelectCard(tab)}
                     isActive={isActive}
                  />
               )
            })}
         </div>
         {/* slider */}
         <Slider
            {...{
               className: 'w-full h-full mt-8 ',
               slides,
               activeIndex
            }}
         />
         <Indicator
            onSelect={(e) => setActiveIndex(e)}
            className='mt-5'
            length={slides.length}
            activeIndex={activeIndex}
         />
      </div>
   )
}
